/** @format */

import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "./components/mainlayout/Header";
import MobileHeader from "./Layout/MobileHeader/MobileHeader";
import Footer from "./components/mainlayout/Footer";
import Skeleton from "react-loading-skeleton";
import MobileView from "./containers/viewContainers/MobileView";
import DesktopView from "./containers/viewContainers/DesktopView";

const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./Layout/Home/index")),
  },
  {
    path: "/payment/:slug",
    exact: true,
    Component: lazy(() => import("./Layout/Payment/index")),
  },
  {
    path: "/details/:slug",
    exact: true,
    Component: lazy(() => import("./Layout/DetailsPage/index")),
  },
  {
    path: "/list",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/index")),
  },
  {
    path: "/list/:Id",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/index")),
  },

  // {
  //   path: "/list/:Id/:city/:stateId/:type",
  //   exact: true,
  //   Component: lazy(() => import("./Layout/ListingPage/index")),
  // },
  {
    path: "/list/:type/:slug/",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/index")),
  },

  {
    path: "/:state_slug/:city_slug/packages/:theme",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },
  {
    path: "/:state_slug/:city_slug/packages",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },
  {
    path: "/:state_slug/packages",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },
  {
    path: "/:state_slug/packages/:theme",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },

  {
    path: "/packages/:theme",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },
  // {
  //   path: "/packages/:slug",
  //   exact: true,
  //   Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  // },
  {
    path: "/packages",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/newListpage.js")),
  },
  {
    path: "/list/:type/:slug/:destinationId/:categoryId",
    exact: true,
    Component: lazy(() => import("./Layout/ListingPage/index")),
  },

  // {
  //   path: "/list/:SId/:CId",
  //   exact: true,
  //   Component: lazy(() => import("./Layout/ListingPage/index")),
  // },
  {
    path: "/enquiry",
    exact: true,
    Component: lazy(() => import("./Layout/Enquiry/index")),
  },
  {
    path: "/faq",
    exact: true,
    Component: lazy(() => import("./Layout/Faq/index")),
  },
  {
    path: "/contact",
    exact: true,
    Component: lazy(() => import("./Layout/Contact/index")),
  },
  {
    path: "/profile/my-account",
    exact: true,
    Component: lazy(() => import("./Layout/Profile/index")),
  },
  {
    path: "/profile/my-bookings",
    exact: true,
    Component: lazy(() => import("./Layout/Profile/index")),
  },
  {
    path: "/profile/change-password",
    exact: true,
    Component: lazy(() => import("./Layout/Profile/index")),
  },
  {
    path: "/changePassword",
    exact: true,
    Component: lazy(() => import("./Layout/ChangePassword/index")),
  },
  {
    path: "/carrer",
    exact: true,
    Component: lazy(() => import("./Layout/Carrer/index")),
  },
  {
    path: "/blogdetails/:Id",
    exact: true,
    Component: lazy(() => import("./Layout/BlogDetails/index")),
  },
  {
    path: "/blog",
    exact: true,
    Component: lazy(() => import("./Layout/Blog/index")),
  },
  {
    path: "/treklist",
    exact: true,
    Component: lazy(() => import("./Layout/TrekListing/index")),
  },
  {
    path: "/trekkingdetails/:slug",
    exact: true,
    Component: lazy(() => import("./Layout/TrekkingDetails/index")),
  },
  {
    path: "/trekkingcheckout",
    exact: true,
    Component: lazy(() => import("./Layout/TrekkingCheckout/index")),
  },
  {
    path: "/thank-you",
    exact: true,
    Component: lazy(() => import("./components/ThankYou/ThankYou")),
  },
  {
    path: "/payment-success",
    exact: true,
    Component: lazy(() => import("./components/Payment/successPage")),
  },
  {
    path: "/payment-failure",
    exact: true,
    Component: lazy(() => import("./components/Payment/failurePage")),
  },
  {
    path: "/policy",
    exact: true,
    Component: lazy(() => import("./components/PolicyPage/PolicyPage")),
  },
  {
    path: "/trek-booking/:slug",
    exact: true,
    Component: lazy(() =>
      import("./components/TrekDetails/TrekBooking/TrekBooking")
    ),
  },
  {
    path: "/tours/:state_slug-:theme-tours",
    exact: true,
    Component: lazy(() =>
      import("./Layout/ListingPage/SEOListingPage/SEOListingPage.js")
    ),
  },
  {
    path: "/tours/:state_slug-tours",
    exact: true,
    Component: lazy(() =>
      import("./Layout/ListingPage/SEOListingPage/SEOListingPage.js")
    ),
  },
  {
    path: "/tours/:state_slug/:city_slug-:theme-tours",
    exact: true,
    Component: lazy(() =>
      import("./Layout/ListingPage/SEOListingPage/SEOListingPage.js")
    ),
  },
  {
    path: "/tours/:state_slug/:city_slug-tours",
    exact: true,
    Component: lazy(() =>
      import("./Layout/ListingPage/SEOListingPage/SEOListingPage.js")
    ),
  },
];

const mapStateToProps = ({ user, settings }) => ({ user, settings });

class App extends React.Component {
  render() {
    const { companyName } = this.props.settings;
    return (
      <Fragment>
        <Helmet title={companyName} />
        <MobileView>
          <MobileHeader />
        </MobileView>
        <DesktopView>
          <Header />
        </DesktopView>

        <Route exact path="/home" render={() => <Redirect to="/" />} />
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <Suspense
                  fallback={
                    <div className="container">
                      <Skeleton height={"50vh"} width={"90vw"} />
                    </div>
                  }
                >
                  <Component />
                </Suspense>
              );
            }}
          />
        ))}
        {!window.location.pathname.includes("/payment/") &&
          !window.location.pathname.includes("/tours/") &&
          !window.location.pathname.includes("/packages") && <Footer />}
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(App));
