/** @format */

import React, { Component } from "react";
import Support from "../../../../assets/img/header/support.svg";
import addUser from "../../../../assets/img/header/add-user.svg";
import APIs from "../../../../global/APIs";
import axios from "axios";
import LoginForm from "../../LoginForm/LoginForm";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Avatar } from "antd";
import UserImage from "../../../../assets/img/header/user_default.svg";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const mapStateToProps = ({ user, dispatch, settings }) => ({
  dispatch,
  user,
  settings,
});

class Header extends Component {
  state = {
    isDestination: false,
    isHoliday: false,
    isHotel: false,
    isDeals: false,
    isActivity: false,
    showContactCard: false,
    generalDetails: {},
    PackageCategoryData: [],
    packageThemeData: [],
    showLoginForm: false,
  };

  componentDidMount() {
    // this.fetchPackageCategory({});
    this.fetchDestinationSlider();
    this.fetchPackageThemeCount();
    this.fetchGeneralDetails();
  }
  handleShowLoginForm = () => {
    this.setState({ showLoginForm: true });
    this.props.dispatch({
      type: "user/SHOW_SIGNUP",
      payload: {
        showLoginForm: true,
      },
    });
  };
  handleCloseLoginForm = () => {
    this.setState({ showLoginForm: false });
    this.props.dispatch({
      type: "user/SHOW_SIGNUP",
      payload: {
        showLoginForm: false,
      },
    });
  };

  fetchGeneralDetails = async (params = {}) => {
    try {
      let data = await axios
        .get(`${APIs}/api/general-open`, {
          params: { ...params },
        })
        .then((res) => res);
      this.setState({
        generalDetails: data.data[0],
      });
      this.props.dispatch({
        type: "settings/CUSTOMISE_PACKAGE",
        payload: {
          companyName: data.data[0]?.company_name,
        },
      });
    } catch (error) {}
  };
  fetchDestinationSlider = async (params = {}) => {
    try {
      let data = await axios
        .get(`${APIs}/client-web-master-apiv1/destination-home`, {
          headers: {
            Authorization: "",
          },
          params: { ...params },
        })
        .then((res) => res);
      this.setState({
        destinationSlider: data.data,
      });
    } catch (error) {}
  };

  fetchPackageCategory = async (params = {}) => {
    try {
      let data = await axios
        .get(`${APIs}/client-package/packagecatcount`, {
          headers: {
            Authorization: "",
          },
          params: { ...params },
        })
        .then((res) => res);
      this.setState({
        PackageCategoryData: data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  fetchPackageThemeCount = async (params = {}) => {
    try {
      let data = await axios
        .get(`${APIs}/client-package/package-theme-count`, {
          headers: {
            Authorization: "",
          },
          params: { ...params },
        })
        .then((res) => res);
      this.setState({
        packageThemeData: data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Destination
  handleDestinationHover = () => {
    this.setState({ isDestination: true });
  };
  handleDestinationLeave = () => {
    this.setState({ isDestination: false });
  };

  // Holiday
  handleHolidayHover = () => {
    this.setState({ isHoliday: true });
  };
  handleHolidayLeave = () => {
    this.setState({ isHoliday: false });
  };

  // Hotel
  handleHotelHover = () => {
    this.setState({ isHotel: true });
  };
  handleHotelLeave = () => {
    this.setState({ isHotel: false });
  };

  // Activity
  handleActivityHover = () => {
    this.setState({ isActivity: true });
  };
  handleActivityLeave = () => {
    this.setState({ isActivity: false });
  };

  // Deals
  handleDealsHover = () => {
    this.setState({ isDeals: true });
  };
  handleDealsLeave = () => {
    this.setState({ isDeals: false });
  };

  // Contact
  handleContactHover = () => {
    this.setState({ showContactCard: true });
  };
  handleContactLeave = () => {
    this.setState({ showContactCard: false });
  };

  handleRoute = (data, each) => {
    if (data?.city_slug) {
      return {
        pathname: `/${data?.state_name}/${data?.city_slug}/packages/${each.package_theme_name}`,
      };
    } else {
      return {
        pathname: `${data?.state_name}/packages/${each?.package_theme_name}`,
      };
    }
  };
  render() {
    var {
      isHoliday,
      isHotel,
      isActivity,
      isDeals,
      isDestination,
      showContactCard,
      generalDetails,
      packageThemeData,
    } = this.state;
    return (
      <React.Fragment>
        <div className="fixed-top bg-white shadow-sm">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <Link to={"/"}>
                    <div className="py-3 my-auto">
                      <img
                        src={generalDetails?.logo}
                        // src="https://glacialadventures.net/images/2494_logo.jpg"
                        width="auto"
                        height="40"
                        class="d-inline-block align-top"
                        alt=""
                      />
                    </div>
                  </Link>
                  <div className="row align-items-center">
                    {/* progress bar  */}
                    {window.location.pathname === "/trekkingcheckout" ? (
                      <div className="d-flex ">
                        <div className="position-relative">
                          <div className="count-progress-bg ">1</div>
                          <div
                            className="th-12 text-center position-absolute th-txt-truncate-1 th-orange"
                            style={{ width: "130px", marginLeft: "-200%" }}
                          >
                            {" "}
                            Everest Base Camp Trek Everest Base Camp Trek
                          </div>
                        </div>
                        <div className="th-prgress-line th-orange"></div>
                        <div className="position-relative">
                          <div className="count-progress-bg ">2</div>
                          <div
                            className="th-12 text-center  position-absolute th-orange"
                            style={{ width: "100px", marginLeft: "-160%" }}
                          >
                            Your Details
                          </div>
                        </div>
                        <div
                          className="th-prgress-line th-light-grey"
                          style={{ color: "#f1f1f1" }}
                        ></div>
                        <div className="position-relative">
                          <div
                            className="count-progress-bg "
                            style={{
                              backgroundColor: "#f1f1f1",
                              color: "#323c47",
                            }}
                          >
                            3
                          </div>
                          <div
                            className="th-12 text-center position-absolute"
                            style={{ width: "100px", marginLeft: "-160%" }}
                          >
                            {" "}
                            Confirm Booking
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div
                          className="py-3"
                          onMouseLeave={this.handleDestinationLeave}
                        >
                          <span
                            className={
                              "px-2 th-pointer " +
                              (isDestination && "th-orange")
                            }
                            onMouseEnter={this.handleDestinationHover}
                          >
                            Destinations
                            {!isDestination ? (
                              <i
                                class="fa fa-angle-down pl-1"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                class="fa fa-angle-up pl-1"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                          {this.state.isDestination && (
                            <div
                              class="th-header-dropdown-large th-dropdown-menu p-3 shadow-lg"
                              aria-labelledby="destinationDropdown"
                              style={{ width: "70%" }}
                            >
                              <div class="row ">
                                {this.state.destinationSlider
                                  ?.slice(0, 8)
                                  .map((data) => (
                                    <div class="col-lg-6 col-sm-6 p-3">
                                      <div class="row align-items-center">
                                        <div class="col-md-3 my-auto">
                                          <span
                                            class="row mx-auto  align-middle"
                                            style={{
                                              backgroundImage: `url(${data?.destonation_logo})`,
                                              width: "56px",
                                              height: "56px",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              borderRadius: "50%",
                                            }}
                                          ></span>
                                        </div>
                                        <div class="col-lg-9 pl-0">
                                          <p class="th-header-dw-title mb-0">
                                            {data?.city_name
                                              ? data?.city_name
                                              : data?.state_name
                                              ? data?.state_name
                                              : data?.country_name}
                                          </p>
                                          <p class="th-header-dw-subtitle mb-0">
                                            {data?.package_theme?.map(
                                              (each, i) => {
                                                return (
                                                  <div
                                                    className="th-header-dw-subtitle th-pointer"
                                                    onClick={() =>
                                                      this.props.history.push(
                                                        this.handleRoute(
                                                          data,
                                                          each
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {data?.city_name
                                                      ? data?.city_name
                                                      : data?.state_name
                                                      ? data?.state_name
                                                      : data?.country_name}{" "}
                                                    {each?.package_theme_name +
                                                      " Package"}
                                                    {i ==
                                                    data?.package_theme
                                                      ?.length -
                                                      1
                                                      ? ""
                                                      : ", "}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="py-3"
                          onMouseLeave={this.handleHolidayLeave}
                        >
                          <span
                            className={
                              "px-2 th-pointer " + (isHoliday && "th-orange")
                            }
                            onMouseEnter={this.handleHolidayHover}
                          >
                            Themes
                            {!isHoliday ? (
                              <i
                                class="fa fa-angle-down pl-1"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                class="fa fa-angle-up pl-1"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                          {isHoliday && (
                            <div
                              class="th-header-dropdown-large th-dropdown-menu p-3 shadow-lg"
                              aria-labelledby="navbarDropdown"
                              style={{ width: "70%" }}
                            >
                              <div class="row ">
                                {packageThemeData?.map((data) => (
                                  <div class="col-lg-6 col-sm-6 p-3">
                                    <a href={`/packages/?category=${data.id}`}>
                                      <div
                                        class="row align-items-center"
                                        onClick={this.handleHolidayLeave}
                                      >
                                        <div class="col-md-3 my-auto">
                                          <span
                                            class="row mx-auto  align-middle"
                                            style={{
                                              backgroundImage: `url(${data.package_category_image_url})`,
                                              width: "56px",
                                              height: "56px",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              borderRadius: "5px",
                                            }}
                                          ></span>
                                        </div>
                                        <div class="col-lg-9 pl-0">
                                          <p class="th-header-dw-title mb-0">
                                            {data.package_theme_name}
                                          </p>
                                          <p class="th-header-dw-subtitle mb-0">
                                            {data.count} Packages
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div
                          className="py-3"
                          onMouseLeave={this.handleActivityLeave}
                        >
                          <span
                            className={
                              "px-2 th-pointer " + (isActivity && "th-orange")
                            }
                            onMouseEnter={this.handleActivityHover}
                          >
                            Activity
                            {!isActivity ? (
                              <i
                                class="fa fa-angle-down pl-1"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                class="fa fa-angle-up pl-1"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                          {isActivity && (
                            <div
                              class="th-header-dropdown-large th-dropdown-menu p-3 shadow-lg"
                              aria-labelledby="navbarDropdown"
                              style={{ width: "70%" }}
                            >
                              <div class="row ">
                                {this.state.PackageCategoryData.slice(0, 4).map(
                                  (data) => (
                                    <div class="col-lg-6 col-sm-6 p-3">
                                      <div class="row align-items-center">
                                        <div class="col-md-3 my-auto">
                                          <span
                                            class="row mx-auto  align-middle"
                                            style={{
                                              backgroundImage: `url(${data.package_category_image_url})`,
                                              width: "56px",
                                              height: "56px",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              borderRadius: "50%",
                                            }}
                                          ></span>
                                        </div>
                                        <div class="col-lg-9 pl-0">
                                          <p class="th-header-dw-title mb-0">
                                            Paragliding
                                          </p>
                                          <p class="th-header-dw-subtitle mb-0">
                                            650+ tours and Starting at ₹ 105,999
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="py-3"
                          onMouseLeave={this.handleDealsLeave}
                        >
                          <span
                            className={
                              "px-2 th-pointer " + (isDeals && "th-orange")
                            }
                            onMouseEnter={this.handleDealsHover}
                          >
                            Hot Deals
                            {!isDeals ? (
                              <i
                                class="fa fa-angle-down pl-1"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                class="fa fa-angle-up pl-1"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                          {isDeals && (
                            <div
                              class="th-header-dropdown-large th-dropdown-menu p-3 shadow-lg"
                              aria-labelledby="navbarDropdown"
                              style={{ width: "80%" }}
                            >
                              <div class="row px-2">
                                {this.state.PackageCategoryData.slice(0, 4).map(
                                  (data) => (
                                    <div class="col-lg-6 col-sm-6 p-2">
                                      <div
                                        class="th-card-promotion2"
                                        style={{
                                          backgroundImage: `url(${data.package_category_image_url})`,
                                          height: "110px",
                                        }}
                                      >
                                        <div class="col-12 th-card-promotion-grad">
                                          <div class="th-card-promotion2-ribbon">
                                            Bestseller
                                          </div>

                                          <div class="row h-100">
                                            <div class="col-7 my-auto">
                                              <div
                                                class="pl-3 th-card-promotion2-title th-24"
                                                style={{ lineHeight: 1.2 }}
                                              >
                                                Amazing Ladakh Luxury Special
                                                2018
                                              </div>
                                            </div>
                                            <div class="col-5">
                                              <span class="th-card-promotion2-justin th-12 text-left">
                                                Just in
                                              </span>
                                              <span class="th-card-promotion2-price th-30">
                                                ₹15,999
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div> */}
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      {/* <Link to={"/contact"}> */}
                      <div
                        class="th-pointer px-2"
                        style={{ marginLeft: "50px" }}
                      >
                        <div
                          class="nav-link th-header-btn shadow-sm"
                          onMouseLeave={this.handleContactLeave}
                        >
                          <span onMouseEnter={this.handleContactHover}>
                            <img
                              src={Support}
                              class="th-header-icon my-auto "
                              alt=" "
                            />{" "}
                            24*7 Support
                          </span>
                          {showContactCard && (
                            <div
                              class="th-dropdown-contact p-3 shadow-lg"
                              aria-labelledby="navbarDropdown"
                              // style={{ width: "80%" }}
                            >
                              <div class="row px-2 justify-content-center border-bottom pb-2">
                                <span className="th-500 th-orange">
                                  Get in touch with us
                                </span>
                              </div>
                              <div class="row px-3 mt-2 align-items-center justify-content-between">
                                <span className="th-400 th-10 mr-2 th-orange">
                                  Tour Enquiry
                                </span>
                                <span className="th-600 th-14">
                                  +91 - {generalDetails?.tour_enquiry}
                                </span>
                              </div>
                              <div class="row px-3 mt-2 align-items-center justify-content-between">
                                <span className="th-400 th-10 mr-2 th-orange">
                                  Hotels
                                </span>
                                <span className="th-600 th-14">
                                  +91 - {generalDetails?.hotel_enquiry}
                                </span>
                              </div>
                              <div class="row px-3 mt-2 align-items-center justify-content-between">
                                <span className="th-400 th-10 mr-2 th-orange">
                                  Transport
                                </span>
                                <span className="th-600 th-14">
                                  +91 - {generalDetails?.transport_enquiry}
                                </span>
                              </div>
                              <div class="row px-3 mt-3 py-1 align-items-center border-top justify-content-between">
                                <span className="th-400 th-10 mr-2 th-grey">
                                  Business Hours
                                </span>
                                <span className="th-500 th-14">
                                  10 AM - 07 PM
                                </span>
                              </div>
                              <div class="row px-3 py-1 border-top align-items-center justify-content-center ">
                                <Link to={"/contact"}>
                                  <MailOutlined className="mr-2" />
                                  <span className="th-18 th-500 ">
                                    Contact Us
                                  </span>
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* </Link> */}
                      {this.props.user.authorized ? (
                        <Link
                          to={{
                            pathname: "/profile/my-account",
                          }}
                        >
                          <div class="th-pointer pl-2">
                            <div class="nav-link th-header-btn shadow-sm">
                              <span className="text-capitalize">
                                <Avatar
                                  src={
                                    this.props.user.avatar
                                      ? this.props.user.avatar
                                      : UserImage
                                  }
                                  size={20}
                                />{" "}
                                Hi,{" "}
                                {this.props.user.first_name
                                  ? this.props.user.first_name
                                  : "Traveller"}
                              </span>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div
                          class="th-pointer pl-2"
                          onClick={() => this.handleShowLoginForm()}
                        >
                          <div class="nav-link th-header-btn shadow-sm">
                            <>
                              <img
                                src={addUser}
                                class="th-header-icon my-auto "
                                alt=" "
                              />{" "}
                              Login or Create Account
                            </>
                          </div>
                        </div>
                      )}
                      {this.props.user.id && (
                        <Link
                          to={{
                            pathname: "/profile/my-bookings",
                          }}
                        >
                          <div class="th-pointer pl-2">
                            <div class="nav-link th-header-btn shadow-sm">
                              <span className="text-capitalize">
                                My Bookings
                              </span>
                            </div>
                          </div>
                        </Link>
                      )}
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 72 }} />
        {this.props.user.showLoginForm && this.state.showLoginForm && (
          <LoginForm handleCloseLoginModal={this.handleCloseLoginForm} />
        )}
      </React.Fragment>
    );
  }
}
export default compose(connect(mapStateToProps), withRouter)(Header);
