/** @format */

import React, { Component } from "react";
import Aux from "../../../../hoc/_Aux";
import Axios from "axios";
import Loader from "../../../Loader";
import Visa from "../../../../assets/img/general/payment-card.jpg";
import Payu from "../../../../assets/img/general/PayUmoney_Logo.jpg";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = { email: "", form_message: "", form_status: false };
  handleEmail = (e) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailPattern.test(e.target.value)) {
      this.setState({ email: e.target.value, form_status: true });
    } else {
      this.setState({ email: e.target.value, form_status: false });
    }
  };
  handleForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.form_status) {
      var formdata = new FormData();
      formdata.append("email", this.state.email);
      Axios.post(
        `https://glacialapi.trixno.com/client-web-master-apiv1/subscribe`,
        formdata,
        {
          headers: {
            Authorization: "",
          },
        }
      )
        .then((res) => {
          this.setState({
            loading: false,
            form_message: "Successfully subcribed to our newsletter.",
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            form_message:
              "Email Already exists or something went wrong please try after some time.",
          });
        });
    } else {
      this.setState({ loading: false, form_message: "Email format is wrong" });
    }
  };
  render() {
    console.log(this.state, "state in footer");
    return (
      <Aux>
        <div class="th-footer px-3">
          <div class="container pt-5">
            <div class="row pb-3">
              <div class="col-lg-3 col-6">
                <h6 class="th-lato th-bold th-18">Type of Holidays</h6>
                <ul class="th-footer-list">
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      About Us
                    </a>
                  </li>
                  <li>
                    <Link class="th-lato th-14 th-grey" to={"/carrer"}>
                      We are Hiring
                    </Link>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Terms and conditions
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href="/policy">
                      Privacy Policy
                    </a>
                  </li>
                  {/* <li>
                    <Link class="th-lato th-14 th-grey" to={"/contact"}>
                      Contact Us
                    </Link>
                  </li> */}
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Gift a Holiday
                    </a>
                  </li>
                  {/* <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Guest Experiences
                    </a>
                  </li> */}
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Photo Gallery
                    </a>
                  </li>
                  <li>
                    <Link class="th-lato th-14 th-grey" to={"/faq"}>
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link class="th-lato th-14 th-grey" to={"/blog"}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Leave your feedback
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-6">
                <h6 class="th-lato th-bold th-18">Glacial Holidays</h6>
                <ul class="th-footer-list">
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Leisure
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Honeymoon Specials
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Adventure
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Group Travel
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Manali Special
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Spiti Specials
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Weekend Getaways
                    </a>
                  </li>
                  <li>
                    <a class="th-lato th-14 th-grey" href=" ">
                      Unexplored
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 mb-3">
                    <h6 class="th-lato th-bold th-18">Recognised By:</h6>
                    <p class="th-lato th-14 th-grey">
                      Himachal Pradesh Tourism
                      <br />
                      Govt. Reg. No:
                      <br />
                      DMA/TA(KGR)-1/2007-865
                    </p>
                  </div>
                  <div class="col-lg-6 col-sm-6 mb-3">
                    <h6 class="th-lato th-bold th-18">Head Office:</h6>
                    <p class="th-lato th-14 th-grey">
                      Himachal Pradesh Tourism
                      <br />
                      Govt. Reg. No:
                      <br />
                      DMA/TA(KGR)-1/2007-865
                    </p>
                  </div>
                  <div class="col-lg-6 col-sm-6 mb-3">
                    <h6 class="th-lato th-bold th-18">For Queries Call:</h6>
                    <p class="th-lato th-14 th-grey">
                      +01902-251450
                      <br />
                      +91-9218663330-35
                      <br />
                      M.D: +91-9218663333
                    </p>
                  </div>
                  <div class="col-lg-6 col-sm-6 mb-3">
                    <h6 class="th-lato th-bold th-18">Branch Office:</h6>
                    <p class="th-lato th-14 th-grey">
                      Himachal Pradesh Tourism
                      <br />
                      Govt. Reg. No:
                      <br />
                      DMA/TA(KGR)-1/2007-865
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              {/* <div class="col-lg-3 col-6 mb-3">
                <p class="th-lato th-14 th-grey">We Accept</p>
                <img src={Visa} style={{ height: "30px" }} alt=" " />
              </div>
              <div class="col-lg-3 col-6 mb-3">
                <p class="th-lato th-14 th-grey">Pay securely by</p>
                <img src={Payu} style={{ height: "30px" }} alt=" " />
              </div> */}
              <div className="col-lg-6"></div>
              <div class="col-lg-6 col-sm-12 text-right mb-3">
                <p class="th-lato th-14 th-grey">
                  Get your best deals in your inbox
                </p>
                <div class="row ml-0 mb-3">
                  <input
                    type="email"
                    class="col-lg-8 col-sm-8 th-footer-input mb-2 mb-sm-0 "
                    placeholder="Enter your email"
                    onChange={this.handleEmail}
                  />
                  <button
                    class="col-lg-4 col-sm-4 th-footer-button px-0 "
                    onClick={this.handleForm}
                  >
                    Submit
                  </button>
                  <div className="th-grey th-14">
                    {this.state.form_message ? this.state.form_message : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          class="modal fade"
          id="th-modal-login"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog th-modal-width-80" role="document">
            <div class="modal-content" style={{ border: "none !important" }}>
              <div
                class="modal-header px-4 py-2"
                style={{ backgroundColor: " #4565ae" }}
              >
                <h5
                  class="modal-title th-lato th-24 th-bold th-white my-auto"
                  id="exampleModalLabel"
                >
                  Login
                </h5>
                <button
                  type="button"
                  class="close my-auto"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i class="las la-times th-icon-white"></i>
                  </span>
                </button>
              </div>
              <form>
                <div class="p-4">
                  
                  <div class="form-group">
                    <input
                      type="email"
                      class="th-login-input form-control pl-4"
                      aria-describedby="emailHelp"
                      placeholder="Email ID"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="th-login-input form-control pl-4"
                      placeholder="Password"
                    />
                  </div>
                  <div class="px-0 th-link-1">
                    <a class="th-lato th-14 th-bold th-orange" href=" ">
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    class="btn th-btn col-md-12 th-lato th-14 th-bold th-white my-4 py-2"
                  >
                    Login
                  </button>
                  <div class="d-flex justify-content-center th-lato th-18 th-grey th-link-1">
                    Create account?&nbsp;{" "}
                    <a
                      class="th-bold th-orange"
                      data-toggle="modal"
                      href=" th-modal-singnup"
                    >
                      Sign Up
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="th-modal-singnup"
          // tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog th-modal-width-80" role="document">
            <div class="modal-content" style={{ border: "none !important" }}>
              <div
                class="modal-header px-4 py-2"
                style={{ backgroundColor: " 4565ae" }}
              >
                <h5
                  class="modal-title th-lato th-24 th-bold th-white my-auto"
                  id="exampleModalLabel"
                >
                  Sign Up
                </h5>
                <button
                  type="button"
                  class="close my-auto"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i class="las la-times th-icon-white"></i>
                  </span>
                </button>
              </div> */}

        {/* <form>
                <div class="p-4">
                  {/* <button class="th-login-fb col-12 p-2 mt-3">
                    <i class="lab la-facebook-f th-icon-white px-3"></i>
                    <spun class="th-lato th-14 th-white">
                      Login with Facebook
                    </spun>
                  </button>
                  <button class="th-login-google col-12 p-2 mt-3">
                    <i class="lab la-google th-icon-white px-3"></i>
                    <spun class="th-lato th-14 th-white">
                      Login with Google
                    </spun>
                  </button>
                  <div class="d-flex justify-content-center p-4 th-lato th-14 th-700 th-grey-2">
                    OR
                  </div> */}
        {/* <div class="form-group">
                    <input
                      type="text"
                      class="th-login-input form-control pl-4"
                      placeholder="Full Name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="th-login-input form-control pl-4"
                      aria-describedby="emailHelp"
                      placeholder="Email ID"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="th-login-input form-control pl-4"
                      placeholder="Password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="th-login-input form-control pl-4"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="tel"
                      class="th-login-input form-control pl-4"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div class="px-0 th-link-1">
                    <a class="th-lato th-14 th-bold th-orange" href=" ">
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    class="btn th-btn col-md-12 th-lato th-14 th-bold th-white my-4 py-2"
                  >
                    Login
                  </button>
                  <div class="d-flex justify-content-center th-lato th-18 th-grey th-link-1">
                    Already have an account?&nbsp;{" "}
                    <a class="th-bold th-orange" href=" th-modal-login">
                      Login
                    </a>
                  </div>
                </div> */}
        {/* </form>  */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </Aux>
    );
  }
}
export default Footer;
