/** @format */

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import APIs from "../../../global/APIs";
import {
  SecurityScanOutlined,
  QuestionCircleOutlined,
  MenuOutlined,
  LogoutOutlined,
  EnvironmentOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { Drawer, Avatar, Modal } from "antd";
import LoginForm from "../../components/LoginForm/LoginForm";
import UserImage from "../../../assets/img/header/user_default.svg";

const mapStateToProps = ({ user, settings }) => ({ user, settings });

const MobileHeader = (props) => {
  console.log("pppppppp", props);
  const UserAuthorised = props.user.authorized;
  const [generalDetails, setGeneralDetails] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleCloseLoginForm = () => {
    setOpenMenu(true);
    props.dispatch({
      type: "user/SHOW_SIGNUP",
      payload: {
        showLoginForm: false,
      },
    });
  };
  const MenuItems = [
    {
      name: "My Account",
      visible: UserAuthorised ? true : false,
      description: "Keep track of all yours tous",
      icon: <GroupOutlined />,
      pathname: "/profile/my-account",
    },
    {
      name: "My Bookings",
      visible: UserAuthorised ? true : false,
      description: "Keep track of all yours tous",
      icon: <GroupOutlined />,
      pathname: "/profile/my-bookings",
      state: {
        tabValue: 2,
      },
    },
    {
      name: "Packages",
      visible: true,
      description: "check out the best packages",
      icon: <EnvironmentOutlined />,
      pathname: "/packages",
    },
    {
      name: "Change Password",
      visible: UserAuthorised ? true : false,
      description: "change your password here",
      icon: <SecurityScanOutlined />,
      pathname: "/profile/change-password",
    },
    {
      name: "Contact Us",
      visible: true,
      description: "Contact our customer Support",
      icon: <QuestionCircleOutlined />,
      pathname: "/contact",
    },
    {
      name: "Logout",
      visible: UserAuthorised ? true : false,
      // description: "Contact our customer Support",
      icon: <LogoutOutlined />,
      pathname: "/contact",
    },
  ];
  const fetchGeneralDetails = async (params = {}) => {
    try {
      let data = await axios
        .get(`${APIs}/api/general-open`, {
          params: { ...params },
        })
        .then((res) => res);
      setGeneralDetails(data.data[0]);
      props.dispatch({
        type: "settings/CUSTOMISE_PACKAGE",
        payload: {
          companyName: data.data[0]?.company_name,
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchGeneralDetails();
  }, []);
  console.log("MobileHeader", props);
  return (
    <>
      <div className="row justify-content-between align-items-center py-2 th-sticky-top">
        <div className="col-6 pl-4">
          <a id="popup" href="/">
            <img
              id="logo"
              src={generalDetails?.logo}
              alt=""
              width="auto"
              height="40"
              class="d-inline-block align-top"
            />
          </a>
        </div>
        <div className="col-6 text-right">
          <div className="th-20 pr-3">
            {
              <MenuOutlined
                style={{ color: "#f84b0f", fontWeight: 800 }}
                onClick={() => {
                  setOpenMenu(true);
                }}
              />
            }
          </div>
        </div>
      </div>
      <Drawer
        visible={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}
        className="th-login-drawer"
        title={
          <div className="row align-items-center">
            <div className="col-3 th-menu-user">
              <Avatar
                src={props.user.authorized ? props.user.avatar : UserImage}
                size={60}
                // style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div
              className="col-9"
              onClick={() => {
                if (!UserAuthorised) {
                  setOpenMenu(false);
                  props.dispatch({
                    type: "user/SHOW_SIGNUP",
                    payload: {
                      showLoginForm: true,
                    },
                  });
                }
              }}
            >
              <div className="row">
                <div className="col-12">
                  <span className="th-22 th-black th-bold">
                    {UserAuthorised
                      ? props?.user?.first_name + " " + props?.user?.last_name
                      : "Login / Signup"}
                  </span>
                </div>
                <div className="col-12 py-1">
                  <span className="th-16 th-grey th-500">
                    {UserAuthorised ? props?.user?.email : "& get started !!"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
        width={"90vw"}
      >
        <div className="row">
          {MenuItems?.filter((el) => el?.visible)?.map((el, index) => {
            return (
              <div className="col-12 py-2">
                <div
                  className="px-3 pb-2"
                  style={{
                    borderBottom: "2px solid #d9d9d9",
                    fontFamily: 'Lato",sans-serif',
                  }}
                >
                  <div
                    className="row justify-content-start align-items-center"
                    onClick={() => {
                      if (el?.name == "Logout") {
                        props.dispatch({
                          type: "user/LOGOUT",
                        });
                      } else {
                        props.history.replace({
                          pathname: el?.pathname,
                        });
                      }

                      setOpenMenu(false);
                    }}
                  >
                    <div className="col-2 px-1 th-menu-icons">{el?.icon}</div>
                    <div className="col-10 pr-1 pl-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="th-bold th-black th-16">
                            {el?.name}
                          </div>
                          <div className="th-light-grey">{el?.description}</div>
                        </div>
                        <div>
                          <span className="th-bold th-26 th-black">&gt;</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Drawer>
      {props.user.showLoginForm && (
        <LoginForm handleCloseLoginModal={handleCloseLoginForm} />
      )}
    </>
  );
};

export default withRouter(connect(mapStateToProps)(MobileHeader));
