/** @format */

import React, { useEffect, useRef } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import { useState } from "react";
import GA_API from "../../../global/APIs";
import axios from "axios";
import { connect } from "react-redux";

import {
  ArrowLeftOutlined,
  SyncOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const mapStateToProps = ({ user, dispatch, settings }) => ({
  dispatch,
  user,
  settings,
});
const LoginForm = (props) => {
  const { dispatch } = props;
  console.log("uyuuyuyuy", props.user);
  const { companyName } = props.settings;
  const signupRef = useRef();
  const [isLogin, setIsLogin] = useState(true);
  const [isOTPLogin, setIsOTPLogin] = useState(true);
  const [isNewLogin, setIsNewLogin] = useState(false);
  const [OTPSent, setOTPSent] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const [validatorType, setValidatorType] = useState("");
  const [loading, setLoading] = useState("");

  const handleSignupFinish = () => {
    const formValues = signupRef.current.getFieldsValue();
    if (isLogin) {
      if (isOTPLogin) {
        dispatch({
          type: "user/OTP_LOGIN",
          payload: {
            contact: enteredValue,
            otp: formValues.otp,
            validatorType: validatorType,
          },
        });
      } else {
        dispatch({
          type: "user/LOGIN",
          payload: {
            username: formValues.username,
            password: formValues.password,
          },
        });
      }
    } else {
      axios
        .post(`${GA_API}/api/user`, {
          ...formValues,
          username: formValues.email,
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          roles: ["27"],
        })
        .then((res) => {
          // message.success("User registered successfully!");
          notification.success({
            message: "Logged In",
            description: "User registered successfully!",
          });
          dispatch({
            type: "user/LOGIN",
            payload: {
              username: formValues.email,
              password: formValues.password,
            },
          });
        })
        .catch((err) => {
          message.error(err.response.data?.message ?? "Something went wrong!");
        });
    }
  };

  const handleGenerateOTP = (e) => {
    setLoading(true);
    let payload;
    if (validatorType === "email") {
      payload = {
        email: enteredValue,
      };
    } else {
      payload = {
        contact: enteredValue,
      };
    }
    axios
      .post(`${GA_API}/apiV1/otp/generate-otp/`, payload)
      .then((res) => {
        message.success("OTP Sent!");
        setOTPSent(true);
        setIsNewLogin(res.data.is_new);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (enteredValue) {
      var emailRegex =
        /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;

      if (enteredValue.match(emailRegex)) {
        setValidatorType("email");
      } else if (!isNaN(enteredValue)) {
        setValidatorType("number");
      } else {
        setValidatorType("");
      }
    }
  }, [enteredValue]);
  return (
    <Modal
      zIndex={1500}
      onCancel={() => {
        dispatch({
          type: "user/SHOW_SIGNUP",
          payload: {
            showLoginForm: false,
          },
        });
      }}
      title={
        <div className='row justify-content-center'>
          <span
            className='th-orange th-600 th-20 pb-2 mr-3'
            style={{ borderBottom: "#f80808 2px solid" }}>
            {isLogin ? "Login" : "Signup"} Form
          </span>
        </div>
      }
      visible={props.user.showLoginForm}
      footer={null}
      centered
      className='th-modal'
      width={window.innerWidth < 600 ? "400px" : "35vw"}>
      <div>
        <Form ref={signupRef} layout='vertical' id='signupForm'>
          <div class='px-3 py-2'>
            {isLogin ? (
              isOTPLogin ? (
                <>
                  {OTPSent ? (
                    <div className='th-card-sub-title pb-2'>
                      <ArrowLeftOutlined
                        className='th-pointer mr-1'
                        onClick={() => {
                          setOTPSent(false);
                        }}
                      />{" "}
                      Please enter the OTP sent to {enteredValue}
                    </div>
                  ) : (
                    <Form.Item
                      name='username'
                      label={
                        <span className='th-orange th-lato'>
                          Email or Mobile Number
                        </span>
                      }
                      class='form-group'>
                      <Input
                        style={{
                          height: "42px",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}
                        onChange={(e) => {
                          setEnteredValue(e.target.value);
                        }}
                        class='th-login-input form-control pl-4'
                        placeholder='Enter email or mobile number'
                      />
                    </Form.Item>
                  )}
                  {OTPSent && (
                    <Form.Item
                      name='otp'
                      class='form-group'
                      rules={[
                        {
                          required: true,
                          message: "OTP is required!",
                        },
                      ]}>
                      <Input
                        type='number'
                        style={{
                          height: "42px",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}
                        class='th-login-input form-control pl-4'
                        placeholder='Enter OTP'
                      />
                    </Form.Item>
                  )}
                </>
              ) : (
                <>
                  <Form.Item
                    name='username'
                    label={<span className='th-orange th-lato'>UserName</span>}
                    rules={[
                      {
                        required: "true",
                        message: "Username is required!",
                      },
                    ]}
                    class='form-group'>
                    <Input
                      style={{
                        height: "42px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      type='text'
                      class='th-login-input form-control pl-4'
                      placeholder='User Name'
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    label={<span className='th-orange th-lato'>Password</span>}
                    class='form-group'
                    rules={[
                      {
                        required: true,
                        message: "Password is required!",
                      },
                    ]}>
                    <Input.Password
                      style={{
                        height: "42px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      class='th-login-input form-control pl-4'
                      placeholder='Password'
                    />
                  </Form.Item>
                </>
              )
            ) : (
              <>
                <Form.Item
                  name='first_name'
                  rules={[
                    {
                      required: "true",
                      message: "Please Enter",
                    },
                  ]}
                  class='form-group'>
                  <Input
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    type='text'
                    class='th-login-input form-control pl-4'
                    placeholder='First Name'
                  />
                </Form.Item>
                <Form.Item
                  name='last_name'
                  rules={[
                    {
                      required: "true",
                      message: "Please Enter",
                    },
                  ]}
                  class='form-group'>
                  <Input
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    type='text'
                    class='th-login-input form-control pl-4'
                    placeholder='Last Name'
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Email is required!",
                    },
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                            value
                          )
                        ) {
                          return Promise.reject(`Invalid email!`);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  name={"email"}
                  class='form-group'>
                  <Input
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    class='th-login-input form-control pl-4'
                    placeholder='Email ID'
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  class='form-group'
                  rules={[
                    {
                      required: true,
                      message: "Password is required!",
                    },
                    {
                      validator: (_, value) => {
                        if (value && !/^.{8,}$/.test(value)) {
                          return Promise.reject(
                            `Password should be of more than 8 characters!`
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}>
                  <Input.Password
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    class='th-login-input form-control pl-4'
                    placeholder='Password'
                  />
                </Form.Item>
                <Form.Item
                  name='confirm'
                  rules={[
                    {
                      required: true,
                      message: "Re-enter password!",
                    },
                    {
                      validator: (_, value) => {
                        if (value && !/^.{8,}$/.test(value)) {
                          return Promise.reject(
                            `Password should be of more than 8 characters!`
                          );
                        }
                        if (
                          value &&
                          value !== signupRef.current.getFieldsValue().password
                        ) {
                          return Promise.reject(`Password doesn't match!`);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  class='form-group'>
                  <Input.Password
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    placeholder='Confirm Password'
                  />
                </Form.Item>
                <Form.Item
                  name={"contact"}
                  rules={[
                    {
                      required: true,
                      message: "Contact is required!",
                    },
                    {
                      validator: (_, value) => {
                        if (value && !/^[0-9]{10}$/.test(value)) {
                          return Promise.reject(`Enter valid mobile number!`);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  class='form-group'>
                  <Input
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    type='tel'
                    class='th-login-input form-control pl-4'
                    placeholder='Mobile Number'
                  />
                </Form.Item>
              </>
            )}
            {isOTPLogin ? (
              <button
                disabled={validatorType == ""}
                loading={loading}
                style={{ cursor: validatorType == "" ? "block" : "pointer" }}
                class='btn th-btn col-md-12 th-lato th-14 th-bold th-white mt-4 mb-2 py-2'
                onClick={() => {
                  if (!loading) {
                    if (!OTPSent) {
                      handleGenerateOTP();
                    } else {
                      handleSignupFinish();
                    }
                  }
                }}>
                {loading && <SyncOutlined spin className='pr-2' />}
                {!OTPSent ? "Generate OTP" : "Sign In"}
              </button>
            ) : (
              <button
                // type="submit"
                form='signupForm'
                class='btn th-btn col-md-12 th-lato th-14 th-bold th-white mt-4 mb-2 py-2'
                onClick={() => {
                  handleSignupFinish();
                }}>
                {isOTPLogin ? "Send OTP" : `Sign ${!isLogin ? "Up" : "In"}`}
              </button>
            )}
            {isLogin && (
              <div class='d-flex justify-content-start'>
                <div
                  class='th-orange th-pointer '
                  onClick={() => setIsOTPLogin(!isOTPLogin)}>
                  Sign in with {!isOTPLogin ? "OTP" : "Password"}
                </div>
              </div>
            )}
            {!isLogin ? (
              <>
                <div class='d-flex justify-content-center th-lato th-18 th-grey th-link-1'>
                  Already have an account?&nbsp;{" "}
                  <div
                    class='th-bold th-orange th-pointer '
                    onClick={() => {
                      setIsLogin(!isLogin);
                      setIsOTPLogin(true);
                    }}>
                    Login
                  </div>
                </div>
              </>
            ) : (
              <div class='d-flex justify-content-center pt-2'>
                <div
                  class='th-btn btn th-pointer '
                  onClick={() => {
                    setIsLogin(!isLogin);
                    setIsOTPLogin(false);
                  }}>
                  <PlusOutlined className='pr-1' /> Create New Account
                </div>
              </div>
            )}
          </div>
        </Form>
        <div className='row'>
          <div className='col-12 text-center th-lato pt-3'>
            By signing in, I understand & agree to {companyName}{" "}
            <a href='' target='_blank'>
              terms of use
            </a>{" "}
            &{" "}
            <a href='' target='_blank'>
              privacy policy
            </a>
            .
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps)(LoginForm);
