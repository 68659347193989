/** @format */

import actions from "./actions";

const initialState = {
  id: "",
  username: "",
  first_name: "",
  last_name: "",
  contact: "",
  email: "",
  avatar: "",
  wrong: false,
  authorized: false,
  loading: false,
  is_active: false,
  login: true,
  showSignUp: false,
  showLoginForm: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SHOW_SIGNUP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
