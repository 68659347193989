/** @format */

import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <div class="loader">
        <div class="outer"></div>
        <div class="middle"></div>
        <div class="inner"></div>
      </div>
    </div>
  );
};

export default Loader;
