/** @format */

import { all, takeEvery, put, call } from "redux-saga/effects";
// import { notification } from 'antd'
import { history } from "../../index";
import { login, currentAccount, logout, otpLogin } from "../../services/users";
import actions from "./actions";

export function* LOGIN({ payload }) {
  const { username, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
      wrong: false,
    },
  });
  const success = yield call(login, username, password);

  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        wrong: false,
        login: false,
      },
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
}

export function* OTP_LOGIN({ payload }) {
  const { contact, otp, validatorType } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
      wrong: false,
    },
  });
  const success = yield call(otpLogin, contact, otp, validatorType);

  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        wrong: false,
        login: false,
      },
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  const response = yield call(currentAccount);
  if (response) {
    const {
      uid: id,
      contact,
      email,
      avatar,
      username,
      first_name,
      last_name,
    } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id: response.id,
        contact,
        username,
        first_name,
        last_name,
        email,
        avatar,
        role: "admin",
        authorized: true,
        avatar,
        showLoginForm: false,
      },
    });
  }
}

export function* LOGOUT() {
  yield call(logout);
  //   toast("Logged out Successfully", { type: "success", autoClose: 2000 });
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      username: "",
      first_name: "",
      last_name: "",
      role: {},
      contact: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      login: true,
    },
  });
  yield history.push("/");
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.OTP_LOGIN, OTP_LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    // takeEvery(actions.SHOW_SIGNUP, SHOWSIGNUP),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
