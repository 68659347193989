/** @format */

const actions = {
  SET_STATE: "user/SET_STATE",
  LOGIN: "user/LOGIN",
  OTP_LOGIN: "user/OTP_LOGIN",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "user/LOGOUT",
  SHOW_SIGNUP: "user/SHOW_SIGNUP",
};

export default actions;
