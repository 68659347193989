/** @format */

import axios from "axios";
import setAuthToken from "../global/setAuthToken";
import store from "store";
import API from "../global/APIs";
import { message, notification } from "antd";
export async function login(contact1, password1) {
  const value = { username: contact1, password: password1 };
  let authorized1 = false;
  return axios
    .post(`${API}/token-auth/`, value)
    .then((response) => {
      if (response.status === 200) {
        authorized1 = true;
        const expirationDate = new Date(new Date().getTime() + 3600 * 10000);
        localStorage.setItem("token", response.data.access);
        store.set("user", response.data.user);
        setAuthToken(response.data.access);
        notification.success({
          message: "Logged In",
          description: "You've successfully logged in",
        });
        return {
          authorized: authorized1,
          token: response.data.token,
        };
      } else if (response.status !== 200) {
        notification.error({
          message: "Invalid Credentials",
        });
      }
    })
    .catch((error) => {
      console.log(error);
      notification.error({
        message: "Invalid Credentials",
      });
    });
}

export async function otpLogin(contact, otp, validatorType) {
  let payload = {};
  if (validatorType === "email") {
    payload = {
      email: contact,
      otp: otp,
    };
  } else {
    payload = {
      contact: contact,
      otp: otp,
    };
  }
  let authorized1 = false;
  return axios
    .post(`${API}/apiV1/otp/verify-otp/`, payload)
    .then((response) => {
      if (response.status === 200) {
        authorized1 = true;
        const expirationDate = new Date(new Date().getTime() + 3600 * 10000);
        localStorage.setItem("token", response.data.token);
        store.set("user", response.data.user);
        setAuthToken(response.data.token);
        notification.success({
          message: "Logged In",
          description: "You've successfully logged in",
        });
        return {
          authorized: authorized1,
          token: response.data.token,
        };
      } else if (response.status !== 200) {
        console.log(response);
        message.error("Something went wrong!");
      }
    })
    .catch((error) => {
      notification.error({
        message: error?.response?.data ?? "Something went wrong!",
      });
    });
}

export async function currentAccount() {
  let authorized1 = false;
  return axios
    .get(`${API}/api/current_user/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      authorized1 = true;
      const { data } = response;
      return {
        id: data.id,
        authorized: authorized1,
        contact: data.contact,
        first_name: data.first_name,
        last_name: data.last_name,
        role: data.roles,
        email: data.email,
        username: data.username,
        token: localStorage.getItem("token"),
        avatar: data.image,
      };
    })
    .catch((error) => {
      // notification.warning({
      //   message: 'Invalid Token Id or Session is Lost',
      //   description: error.message,
      // })
    });
}

export async function logout() {
  setAuthToken("");
  window.localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("user");
}
